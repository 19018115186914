.App {
  display: flex;
  flex-flow: wrap;
  margin: 0;
  padding: 0;
  width: 99vw;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
}

.ss-close:after{
  position: absolute;
  right: 50px;
  top: 23px;
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
}